@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b1d1b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* map.jsx styles */
.map {
  height: 500px;
  background-color: white;
  padding: 1rem;
  border-radius: 20px;
  margin-top: 16px;
  box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.5);
}

.map .leaflet-container {
  height: 100%;
  border-radius: 12px;
}
/* map.jsx styles */


/* HEADER COMPONENT ANTD MENU STYLE */
.site-layout-background .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.menu-style {
  color: white !important;
  background-color: #27749C !important;
}

.site-layout-background .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.menu-style::after{
  border-bottom: none !important;
}

.site-layout-background .ant-menu-overflow-item.ant-menu-item:hover{
  color: white !important;
  background-color: #27749C !important;
}

.site-layout-background .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu{
  position: relative;
  top: 0px !important;
}
/* HEADER COMPONENT ANTD MENU STYLE */


.radarHeader {
  color: white;
  text-align: center;
  font-size: 2vw;
}